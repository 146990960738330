import React, { useState, useCallback } from 'react'
import TaxSlabs20222023 from "../components/tax-slabs-2022-2023"
import { Link } from "gatsby";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import taxFlow from '../images/tax-flow.png'
import Banner from '../components/ads/banner'

function TaxCalculator2022() {
    const [inputValue, setInputValue] = useState('');
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [monthlyTax, setMonthlyTax] = useState(0);
    const [monthlyTaxPer, setMonthlyTaxPer] = useState("0.0");
    const [incomeAfterTax, setIncomeAfterTax] = useState(0);
    const [incomeAfterTaxPer, setIncomeAfterTaxPer] = useState("0.0");
    const [yearlyIncome, setYearlyIncome] = useState(0);
    const [yearlyTax, setYearlyTax] = useState(0);
    const [yearlyTaxPer, setYearlyTaxPer] = useState("0.0");
    const [yearlyIncomeAfterTax, setYearlyIncomeAfterTax] = useState(0);
    const [yearlyIncomeAfterTaxPer, setYearlyIncomeAfterTaxPer] = useState("0.0");
    const [downloadBtn, setDownloadBtn] = useState(false);
    const [incomePercentage, setIncomePercentage] = useState(100);
    const [taxPercentage, setTaxPercentage] = useState(0);

    var options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        colors: ['#198754', '#f20808', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
        title: {
            text: 'Monthly Income Breakdown'
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Salary Devision',
            colorByPoint: true,
            data: [{
                name: 'Net Income',
                y: incomePercentage,
                sliced: true
            }, {
                name: 'Tax',
                y: taxPercentage
            }]
        }]
    }

    const numberInput = useCallback((inputElement) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    function isNumeric(n) {
        return /^[0-9,.]*$/.test(n);
    }
    function round(num) {
        return +(Math.round(num));
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function calculateTax(amount) {
        var taxAmount = 0;
        if (amount > 600000 && amount <= 1200000) {
            amount -= 600000;
            taxAmount = amount * 0.025;
        } else if (amount > 1200000 && amount <= 2400000) {
            amount -= 1200000;
            taxAmount = 15000 + amount * 0.125;
        } else if (amount > 2400000 && amount <= 3600000) {
            amount -= 2400000;
            taxAmount = 165000 + amount * 0.2;
        } else if (amount > 3600000 && amount <= 6000000) {
            amount -= 3600000;
            taxAmount = 405000 + amount * 0.25;
        } else if (amount > 6000000 && amount <= 12000000) {
            amount -= 6000000;
            taxAmount = 1005000 + amount * 0.325;
        } else if (amount > 12000000) {
            amount -= 12000000;
            taxAmount = 2955000 + amount * 0.35;
        }

        return round(taxAmount);
    }
    const handleInputChange = (e) => {
        const monthlySalary = e.target.value;
        if (isNumeric(monthlySalary) && monthlySalary !== "") {
            const income = monthlySalary.replace(/,/g, '');
            setInputValue(monthlySalary);
            setMonthlyIncome(numberWithCommas(income));
            const taxAmount = calculateTax(income * 12);
            setMonthlyTax(numberWithCommas(round(taxAmount / 12)));
            setMonthlyTaxPer(((taxAmount * 100 / 12) / income).toFixed(1));
            setTaxPercentage(((taxAmount * 100 / 12) / income));
            setIncomeAfterTax(numberWithCommas(round(income - round(taxAmount / 12))));
            setIncomeAfterTaxPer((((income - (taxAmount / 12)) * 100) / income).toFixed(1));
            setIncomePercentage((((income - (taxAmount / 12)) * 100) / income));
            setYearlyIncome(numberWithCommas(round(income * 12)));
            setYearlyTax(numberWithCommas(taxAmount));
            setYearlyTaxPer(((taxAmount * 100) / (income * 12)).toFixed(1));
            setYearlyIncomeAfterTax(numberWithCommas(((income * 12) - taxAmount)));
            setYearlyIncomeAfterTaxPer(((((income * 12) - taxAmount) * 100) / (income * 12)).toFixed(1));
            setDownloadBtn(true);
        }
        else if (monthlySalary === "") {
            setInputValue("");
            setMonthlyIncome(0);
            setMonthlyTax(0);
            setMonthlyTaxPer("0.0");
            setIncomeAfterTax(0);
            setIncomeAfterTaxPer("0.0");
            setYearlyIncome(0);
            setYearlyTax(0);
            setYearlyTaxPer("0.0");
            setYearlyIncomeAfterTax(0);
            setYearlyIncomeAfterTaxPer("0.0");
            setTaxPercentage(0);
            setIncomePercentage(100);
            setDownloadBtn(false);
        }
    }

    return (
        <>
            <div className='row mb-4 calculations'>
                <div className='col-12 textCenter'>
                    <h1>Income Tax Calculator Pakistan 2022 - 2023</h1>
                    <p>Calculate income tax on salary using following calculator as per 2022-2023 federal budget.</p>
                </div>
                <div className='col-md-6'>
                    <input type="text" className='cal-container mb-4' placeholder="Your Monthly Salary / Income"
                        value={inputValue}
                        ref={numberInput}
                        onChange={handleInputChange}
                    />
                    <div className='row mb-3'>
                        <div className='col-7'>Monthly Income</div>
                        <div className='col-5'>{monthlyIncome}</div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-7'>Monthly Tax</div>
                        <div className='col-5'>{monthlyTax} <span className='text-danger'>({monthlyTaxPer}%)</span></div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-7'>Income After Tax</div>
                        <div className='col-5'>{incomeAfterTax} <span className='text-success'>({incomeAfterTaxPer}%)</span></div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-7'>Yearly Income</div>
                        <div className='col-5'>{yearlyIncome}</div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-7'>Yearly Tax</div>
                        <div className='col-5'>{yearlyTax} <span className='text-danger'>({yearlyTaxPer}%)</span></div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-7'>Yearly Income After Tax</div>
                        <div className='col-5'>{yearlyIncomeAfterTax} <span className='text-success'>({yearlyIncomeAfterTaxPer}%)</span></div>
                    </div>
                    {downloadBtn && (
                        <div className='row mb-3 print-btn'>
                            <div className='col-12'><button className='btn btn-danger' onClick={() => { window.print() }}>Download Calculations</button></div>
                        </div>
                    )}
                </div>
                <div className='col-md-6 text-center pt-4 income-chart'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
                <div className='col-md-12'>
                    Click here for <Link to="/">Pakistan Income Tax Calculator 2024-2025</Link>
                </div>
                <div className='col-md-12'>
                    Click here for <Link to="/tax-calculator-2023-2024/">Pakistan Income Tax Calculator 2023-2024</Link>
                </div>
            </div>
            <div className='row mb-4 print-hide'>
                <div className='col-12 text-center'>
                    <Banner></Banner>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <TaxSlabs20222023></TaxSlabs20222023>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h2>Understanding Taxation in Pakistan: A Comprehensive Guide</h2>
                    <p>Taxation is a vital aspect of every country's economy, and Pakistan is no exception. As a responsible citizen, it is important to have a clear understanding of the tax laws and regulations in Pakistan to ensure compliance and avoid penalties. In this article, we will provide a comprehensive guide to the various types of taxes in Pakistan and how they are calculated.</p>
                    <p>Calculating taxes can be a daunting task for many individuals and businesses in Pakistan. It is important to have a clear understanding of the tax laws and regulations in order to ensure compliance and avoid any penalties. In this article, we will provide a comprehensive overview of the tax calculation process in Pakistan and help you navigate the complexities of the tax system.</p>
                    <h2>Taxation in Pakistan</h2>
                    <p>Pakistan has a federal system of government and the power to levy taxes is divided between the federal and the provincial governments. The Federal Board of Revenue (FBR) is responsible for the administration of taxes at the federal level, while the Provincial Revenue Authorities (PRAs) are responsible for the administration of taxes at the provincial level.</p>
                    <h2>Types of Taxes in Pakistan</h2>
                    <p>There are various types of taxes in Pakistan, including:</p>
                    <ul>
                        <li><strong>Income Tax:</strong> Tax on individuals and businesses on the basis of their income.</li>
                        <li><strong>Sales Tax:</strong> Tax on the sale of goods and services.</li>
                        <li><strong>Federal Excise Duty:</strong> Tax on the production and import of goods.</li>
                        <li><strong>Withholding Taxes:</strong> Taxes withheld at the source of income, such as on salary and commission.</li>
                        <li><strong>Property Tax:</strong> Property tax is a tax that is levied on the ownership of property.</li>
                    </ul>
                    <h3>Income Tax</h3>
                    <p>Income tax is the most commonly encountered tax in Pakistan, and it is levied on individuals and companies based on their income. The income tax rate in Pakistan ranges from 5% to 35% depending on the individual's or company's income bracket. The income tax is calculated on the basis of the taxable income, which is the income earned after deducting expenses and exemptions.</p>
                    <h3>Sales Tax</h3>
                    <p>Sales tax is a consumption-based tax that is levied on the sale of goods and services. In Pakistan, the sales tax rate is 18%, and it is applied on the final price of the product or service. Sales tax is collected by the Federal Board of Revenue (FBR) and is deposited into the government's treasury.</p>
                    <h3>Federal Excise Duty (FED)</h3>
                    <p>Federal Excise Duty is a tax that is levied on the manufacture of goods within Pakistan. The FED rate ranges from 1% to 25% depending on the type of product being manufactured. The FED is calculated on the value of the goods produced and is collected by the FBR.</p>
                    <h3>Withholding Tax</h3>
                    <p>Withholding tax is a tax that is collected at the source of income. This means that the tax is deducted from the income at the time of payment. In Pakistan, withholding tax is collected on various types of income such as rent, commission, and professional fees. The withholding tax rate ranges from 2% to 20% depending on the type of income.</p>
                    <h3>Property Tax</h3>
                    <p>Property tax is a tax that is levied on the ownership of property. In Pakistan, property tax is calculated based on the value of the property and is collected by the local government. The property tax rate in Pakistan ranges from 0.5% to 2% depending on the value of the property.</p>
                    <h2>Tax Calculation for Businesses</h2>
                    <p>Businesses in Pakistan are required to file their income tax returns on an annual basis. The income tax rate for companies is 30%, regardless of the level of income. In addition to income tax, businesses are also required to pay other taxes, such as sales tax and federal excise duty.</p>
                    <img className='img-fluid mx-auto d-block' src={taxFlow} alt="Tax Flow in Pakistan" />
                    <p>As you can see in the above diagram, income tax is levied on individuals and businesses, which is administrated by the Federal Board of Revenue (FBR) and Provincial Revenue Authorities (PRAs) respectively.</p>
                    <h2>What is an Income / Salary Tax Calculator?</h2>
                    <p>An income tax calculator is a tool that allows you to estimate the amount of income tax you may owe based on your income, filing status, and other factors. To use an income tax calculator, you will need to enter information such as your gross income, deductions, and exemptions. The calculator will then use this information to estimate your tax liability based on the applicable tax rates and rules. This calculator can be used as fbr tax calculator.</p>
                    <p>There are many different income tax calculators available online, and they can vary in terms of the level of detail and complexity. Some income tax calculators may only provide a rough estimate of your tax liability, while others may offer more detailed calculations and allow you to enter more information about your specific tax situation.</p>
                    <p>It's important to note that an income tax calculator is only a tool to provide an estimate of your tax liability. The actual amount of tax you owe may be different due to various factors, such as changes to tax laws, errors in the calculator, or discrepancies in your tax return. For the most accurate estimate of your tax liability, it is recommended to consult a tax professional or use tax preparation software.</p>
                    <h2>The Importance of Tax Compliance</h2>
                    <p>It is important to comply with the tax laws and regulations in Pakistan to avoid penalties and legal action. Non-compliance can result in fines and penalties, and in severe cases, criminal proceedings may be initiated. To ensure compliance, it is recommended to seek the assistance of a tax professional or consult the FBR website for guidance.</p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <h2>Frequently Asked Questions</h2>
                    <h5><strong className='text-danger'>Q.</strong> How to calculate income tax in Pakistan?</h5>
                    <p><strong className='text-success'>A.</strong> Tax calculation in Pakistan on your income is straightforward using our given tax calculator above. Just enter your monthly salary and you will get all your monthly and yearly tax calculations with percentages using the current tax slabs for 2022-2023.</p>
                    <h5><strong className='text-danger'>Q.</strong> How much is income tax in Pakistan?</h5>
                    <p><strong className='text-success'>A.</strong> Income tax in Pakistan varies based on your yearly total salary. You can view your income tax calculation using the income tax calculator, or you can check the different <Link to='/tax-slabs-2022-2023/'>income tax slabs</Link> on our websites for the fiscal year 2022 to 2023.</p>
                    <h5><strong className='text-danger'>Q.</strong> What is income tax rate in Pakistan?</h5>
                    <p><strong className='text-success'>A.</strong> The tax slabs in Pakistan define the income tax rate. You can calculate your actual income tax by entering your total annual income/salary into the new tax slab and using the formula to calculate the tax rate applied to your income.</p>
                    <h5><strong className='text-danger'>Q.</strong> How much salary is taxable in Pakistan?</h5>
                    <p><strong className='text-success'>A.</strong> If your total annual income exceeds PKR 60,000, your salary is taxable under the government of Pakistan's budget for fiscal year 2022-2023.</p>
                    <h5><strong className='text-danger'>Q.</strong> How to file income tax return in Pakistan?</h5>
                    <p><strong className='text-success'>A.</strong> Filing tax returns in Pakistan is simple. Just register on the <Link to='https://iris.fbr.gov.pk/' target="_blank">Iris FBR</Link> website and select the return form based on your income source (such as, salary or business), and it will walk you through all of the necessary details. You can also use the <Link to='https://play.google.com/store/apps/details?id=com.pral.fbr_varification_system' target="_blank">Tax Asaan mobile app</Link> to file your income tax more easily.</p>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <p>Non-filer required to file a tax return and ATL fee u/s 182 of Tax ordinance 2001 Pakistan. Which may vary between individuals. <span style={{ fontSize: "16px" }}>If you want to learn how to check NTN Status in Pakistan, click on the below link.</span></p>
                    <p><Link to="/ntn-status/">Check NTN Status Online</Link></p>
                    <p>If you have a registered NTN that's not mean you are Tax Filer. You also need to check the Filer and Non-Filer status, FBR updates the ATL on the 1st of March each year. <Link to="/atl-status/">Check ATL Status Online</Link></p>
                </div>
            </div>
            <div className='row mb-4 print-show'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-12'>
                    <h3 className='print-title'>Before you use the calculator</h3>
                    <h3 className='d-none print-show'>Disclaimer</h3>
                    <p>The information you provide at the time of calculation, is used to calculate the results. You should only use the results of this income tax calculator {new Date().getFullYear()} as an estimate and for guidance.</p>
                    <p>The exact amount of your income tax can only be calculated after you file your tax return.</p>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
        </>
    );
}

export default TaxCalculator2022;