import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxCalculator2022 from "../components/tax-calculator-2022-2023"

const TaxCalculator20222023 = () => (
  <Layout>
    {/* <Seo title="Tax Calculator Pakistan - 2023-2024" /> */}
    <TaxCalculator2022></TaxCalculator2022>
  </Layout>
)
export const Head = () => <Seo title="Income Tax Calculator Pakistan - 2022-2023" />

export default TaxCalculator20222023
